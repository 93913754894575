import React, { Fragment, PropsWithChildren } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { XIcon } from '@heroicons/react/solid';

interface IModal extends PropsWithChildren {
    show: boolean;
    title?: string;
    afterClose: () => void;
    closeable?: boolean;
    id?: string;
}

export const Modal: React.FC<IModal> = (props) => {
    const { children, show, title = '', afterClose, closeable = false, id } = props;

    return (
        <div className="relative" key={id ?? undefined}>
            {show && (
                <>
                    <div className="fixed top-0 left-0 z-[500] flex h-screen w-screen bg-black/70 shadow-xl backdrop-blur-sm"></div>
                </>
            )}

            <Transition appear show={show} as={Fragment}>
                <Dialog
                    as="div"
                    className="fixed inset-0 z-[1000] flex h-full items-center justify-center overflow-y-auto"
                    onClose={() => afterClose()}
                >
                    <div className="relative flex h-screen w-screen items-center justify-center p-2 text-center sm:p-4 md:h-max ">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            {/*Content Modal*/}
                            <div className="relative flex h-max w-full max-w-4xl transform flex-col rounded-2xl bg-white p-2 shadow-xl transition-all">
                                {closeable && (
                                    <div className="absolute top-4 right-4 z-50 flex h-8 w-full items-end justify-end">
                                        <button
                                            className="relative h-8 w-8 rounded-full bg-transparent p-1 transition-all hover:bg-secondary hover:text-white"
                                            onClick={() => afterClose()}
                                        >
                                            <XIcon />
                                        </button>
                                    </div>
                                )}

                                <div className="relative flex h-full w-full max-w-4xl flex-col overflow-y-auto overflow-x-hidden p-2 text-left align-middle">
                                    <Dialog.Title
                                        as="h3"
                                        className="text-center font-serif font-medium text-lg leading-6 text-primary"
                                    >
                                        {title ?? ''}
                                    </Dialog.Title>

                                    <div className="flex w-full flex-col py-4">{children}</div>
                                </div>
                            </div>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition>
        </div>
    );
};

import React, { Fragment, PropsWithChildren } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { ButtonRound } from '../Buttons/ButtonRound';

interface IModal extends PropsWithChildren {
    show: boolean;
    title?: string;
    afterClose: () => void;
    closeable?: boolean;
    transparent?: boolean;
    noPadding?: boolean;
}

export const ModalFullscreenNeutral: React.FC<IModal> = (props) => {
    const {
        children,
        show,
        title = '',
        afterClose,
        closeable = false,
        transparent = false,
        noPadding,
    } = props;

    return (
        <>
            {show && !transparent && (
                <>
                    <div className="fixed top-0 left-0 z-[500] flex h-screen w-screen bg-neutral-500/10 shadow-xl backdrop-blur-sm"></div>{' '}
                </>
            )}

            <Transition appear show={show} as={Fragment}>
                <Dialog
                    as="div"
                    className="fixed inset-0 z-[1000] h-full w-full overflow-y-auto"
                    onClose={() => afterClose()}
                >
                    {/* Body */}
                    <Transition.Child
                        as="div"
                        className={`absolute inset-0 flex h-screen max-h-screen w-screen items-center justify-center ${
                            !transparent ? 'bg-neutral-800/70 backdrop-blur-sm' : 'bg-transparent'
                        } `}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                    >
                        {/* Inner Wrapper */}
                        <div className="relative flex h-full w-full flex-col">
                            {/* Header */}
                            {title && (
                                <div className="relative hidden h-10 w-full flex-row items-center justify-center pt-2 lg:flex">
                                    <Dialog.Title
                                        as="h3"
                                        className="text-center font-serif font-medium text-lg leading-6 tracking-wider text-white"
                                    >
                                        {title ?? ''}
                                    </Dialog.Title>
                                </div>
                            )}
                            <div className="relative flex h-full w-full flex-col items-center justify-center overflow-x-hidden">
                                <div
                                    className={`relative box-content flex w-max max-w-6xl flex-col bg-white ${
                                        noPadding ? '' : 'p-10'
                                    }`}
                                >
                                    {/* Moved here into white content box: https://webbar.youtrack.cloud/issue/RMC-443 */}
                                    {closeable && (
                                        <div className="absolute right-3 top-3 z-50 flex h-8 w-full items-end justify-end">
                                            <ButtonRound
                                                icon={faTimes}
                                                onClick={() => afterClose()}
                                                clean={true}
                                            />
                                        </div>
                                    )}
                                    {children}
                                </div>
                            </div>
                        </div>
                    </Transition.Child>
                </Dialog>
            </Transition>
        </>
    );
};

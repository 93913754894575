import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { useToggle } from 'react-use';
import { passwordRegex } from '../../regex/passwordRegex';

interface ITextfield {
    label: string;
    value: string;
    icon?: IconProp;
    placeholder?: string;
    type?: 'text' | 'password' | 'date';
    autocomplete?: string;
    name?: string;
    id?: string;
    onChange?: (value: string) => void;
    onKeyPress?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
    reference?: React.MutableRefObject<HTMLInputElement> | null;
    validation?: 'email' | 'url' | 'number' | 'phone' | 'custom' | 'password';
    validationRegEx?: RegExp;
    validationMessage?: string;
    reportValidation?: (id: string, isValid: boolean) => void;
    required?: boolean;
    minDate?: string;
    errorText?: string;
}

interface IValidation {
    isValid: boolean;
    message: string;
}

interface IAdditionalProps {
    id?: string;
    name?: string;
}

// TODO Soll depreacted werden

export const Textfield: React.FC<ITextfield> = (props) => {
    const {
        icon,
        value,
        label,
        autocomplete,
        type = 'text',
        placeholder,
        onChange,
        onKeyPress,
        reference,
        id,
        name,
        validation,
        validationRegEx,
        validationMessage = 'Wrong format',
        reportValidation,
        required,
        minDate,
        errorText,
    } = props;

    const [currentValue, setCurrentValue] = useState(value ?? '');

    const handleChange = useCallback(
        (evt: ChangeEvent<HTMLInputElement>) => {
            setCurrentValue(evt.target.value ?? '');

            if (onChange) onChange(evt.target.value);
        },
        [onChange]
    );

    const [showPassword, toggleShowPassword] = useToggle(false);

    const defaultValue = useMemo(() => {
        return value;
    }, [value]);

    /**
     * Reset Default Value, if value changes from outside
     */
    useEffect(() => {
        if (defaultValue) {
            setCurrentValue(defaultValue);
        }
    }, [defaultValue]);

    const additionalInputProps = useMemo(() => {
        const newProps: IAdditionalProps = {};

        if (id && id !== '') {
            newProps.id = id;
        }

        if (name && name !== '') {
            newProps.name = name;
        }

        return newProps;
    }, [id, name]);

    const regexEMail = useMemo(() => {
        return /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/;
    }, []);

    const regexUrl = useMemo(() => {
        return /https?:\/\/(?:www\.)?([-a-zA-Z0-9@:%._\\+~#=]{2,256}\.[a-z]{2,6}\b)*(\/[\\/\w.-]*)*[\\?]*(.+)*/;
    }, []);

    const regexInteger = useMemo(() => {
        return /^\d+$/g;
    }, []);

    const regexPhone = useMemo(() => {
        return /\+?\d{1,4}?[-.\s]?\(?\d{1,3}?\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}/g;
    }, []);

    const regexPassword = useMemo(() => {
        return passwordRegex;
    }, []);

    const forbiddenChars = useMemo((): string[] => {
        return ['<', '>', '\\', '{', '}'];
    }, []);

    const validationState = useMemo((): IValidation => {
        // Check for böse
        if (type !== 'password') {
            let forbidden: IValidation | null = null;
            forbiddenChars.forEach((forbiddenItem) => {
                if (value && value.includes(forbiddenItem)) {
                    const chars = forbiddenChars.join('  ');

                    forbidden = {
                        isValid: false,
                        message: `Forbidden characters ${chars}`,
                    };
                }
            });
            if (forbidden) return forbidden;
        }

        // Check for required
        if (required && (value === '' || !value)) {
            return {
                isValid: false,
                message: errorText ? errorText : 'Field is required',
            };
        }

        // Actual validation
        if ((currentValue !== null && !required && value !== '') || required) {
            switch (validation) {
                case 'password':
                    if (!regexPassword.test(currentValue)) {
                        return {
                            isValid: false,
                            message: 'Min. 6 characters, 1 uppercase, 1 number, 1 special',
                        };
                    }
                    break;
                case 'email':
                    if (!regexEMail.test(currentValue)) {
                        return {
                            isValid: false,
                            message: "Doesn't look like an e-mail",
                        };
                    }
                    break;
                case 'phone':
                    if (!regexPhone.test(currentValue)) {
                        return {
                            isValid: false,
                            message: 'Use this format: +XX XXXX XXXXXX',
                        };
                    }
                    break;
                case 'number':
                    if (!regexInteger.test(currentValue)) {
                        return {
                            isValid: false,
                            message: 'Only integer allowed',
                        };
                    }
                    break;
                case 'url':
                    if (!regexUrl.test(currentValue)) {
                        return {
                            isValid: false,
                            message: "Doesn't look like an URL",
                        };
                    } else {
                        if (
                            currentValue &&
                            !currentValue.trim().toLowerCase().startsWith('https://')
                        ) {
                            return {
                                isValid: false,
                                message: 'URL must start with https (SSL)',
                            };
                        }
                    }
                    break;
                case 'custom':
                    if (validationRegEx && !validationRegEx.test(currentValue)) {
                        return {
                            isValid: false,
                            message: validationMessage,
                        };
                    } else if (errorText) {
                        return {
                            isValid: false,
                            message: errorText,
                        };
                    }
                    break;
            }
        }

        return {
            isValid: true,
            message: '',
        };
    }, [
        currentValue,
        errorText,
        forbiddenChars,
        regexEMail,
        regexInteger,
        regexPassword,
        regexPhone,
        regexUrl,
        required,
        type,
        validation,
        validationMessage,
        validationRegEx,
        value,
    ]);

    useEffect(() => {
        if (reportValidation && id) {
            reportValidation(id, validationState.isValid ?? true);
        }
    }, [id, reportValidation, validationState]);

    const borderStyles = useMemo(() => {
        if (!validationState.isValid) return 'border-red-600';
        return 'border-neutral-300';
    }, [validationState.isValid]);

    const inputType = useMemo(() => {
        switch (type) {
            case 'password':
                return showPassword ? 'text' : 'password';
            default:
                return type;
        }
    }, [showPassword, type]);

    const inputWrapperGrid = useMemo(() => {
        if (icon) return 'grid grid-cols-[50px,auto]';

        return 'flex flex-row';
    }, [icon]);

    const textFieldText = useMemo(() => {
        if (type === 'date') return 'text-primary font-light text-xs';

        return 'text-primary text-sm';
    }, [type]);

    return (
        <>
            <div className="relative flex w-full flex-col gap-1">
                <label className="input-label relative w-full">
                    {label}
                    {type === 'password' && (
                        <FontAwesomeIcon
                            icon={showPassword ? faEye : faEyeSlash}
                            className="absolute right-1 bottom-0 text-base text-neutral-200"
                            onClick={() => toggleShowPassword()}
                        />
                    )}
                </label>
                <div className={`${inputWrapperGrid} w-full gap-0`}>
                    {icon && (
                        <div className="flex items-center justify-center rounded-tl rounded-bl bg-neutral-300">
                            <FontAwesomeIcon icon={icon} className=" text-white" />
                        </div>
                    )}
                    <input
                        {...additionalInputProps}
                        className={`placeholder:text-light relative w-full rounded-tr rounded-br  ${textFieldText} ${borderStyles}`}
                        type={inputType}
                        value={currentValue}
                        onChange={handleChange}
                        autoComplete={autocomplete}
                        data-lpignore="false"
                        placeholder={placeholder}
                        min={minDate ?? undefined}
                        ref={reference ?? null}
                        onKeyDown={(event) => {
                            if (onKeyPress) onKeyPress(event);
                        }}
                    />
                </div>
                {!validationState.isValid && (
                    <>
                        <div className="absolute -bottom-4 left-1 text-xs text-red-600">
                            {validationState.message}
                        </div>
                    </>
                )}
            </div>
        </>
    );
};
